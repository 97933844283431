<aside #mainSidebar class="main-sidebar sidebar-dark-primary elevation-4">
  <!-- Brand Logo -->
  <a [routerLink]="['/']" class="brand-link">
    <img src="assets/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
      style="opacity: 0.8" />
    <span class="brand-text font-weight-light">OMS</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <app-user-avatar [username]="authService.getUsername()" width="33px"></app-user-avatar>
      </div>
      <div class="info">
        <a [routerLink]="['/profile']" class="d-block">{{
          authService.getUsername()
          }}</a>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column nav-child-indent nav-legacy" data-widget="treeview" role="menu"
        data-accordion="true">
        <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
        <!-- <li class="nav-item">
					<a [routerLink]="['/']" class="nav-link">
						<i class="nav-icon fas fa-tachometer-alt"></i>
						<p>Dashboard</p>
					</a>
				</li> 
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/orders', 'all']">
            <i class="nav-icon fas fa-shopping-bag"></i>
            <p>Orders</p>
          </a>
        </li> -->
        <li class="nav-item has-treeview">
          <a class="nav-link" href="#">
            <i class="nav-icon fas fa-shopping-bag"></i>
            <p>
              Orders
              <i class="fas fa-angle-right right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['orders', 'all']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>All</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['orders', 'active']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Active</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['orders', 'closed']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Closed</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['orders', 'cancelled']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Cancelled</p>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a [routerLink]="['orders', 'hold']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>On Hold</p>
              </a>
            </li> -->
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/orders', 'payment']" replaceUrl="true">
            <i class="nav-icon fas fa-money-bill-alt"></i>
            <p>Payments</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/orders', 'fulfillment']" replaceUrl="true">
            <i class="nav-icon fas fa-shipping-fast"></i>
            <p>Fulfillments</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/orders', 'rts']" replaceUrl="true">
            <i class="nav-icon fas fa-dolly"></i>
            <p>RTS</p>
          </a>
        </li>
        <!-- <li class="nav-item has-treeview">
          <a class="nav-link" href="#">
            <i class="nav-icon fas fa-dolly"></i>
            <p>
              RTS
              <i class="fas fa-angle-right right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['orders', 'rts', 'pending']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Pending RTS</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['orders', 'rts', 'released']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Released RTS</p>
              </a>
            </li>
          </ul>
        </li> -->
        <li class="nav-item has-treeview">
          <a class="nav-link" href="#">
            <i class="nav-icon fas fa-coins"></i>
            <p>
              Commissions
              <i class="fas fa-angle-right right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['orders', 'commission', 'pending']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Pending Commissions</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['orders', 'commission', 'pushed']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Pushed Commissions</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['orders', 'commission', 'credited']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Credited Commissions</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['orders', 'commission', 'cancelled']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Cancelled Commissions</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['orders', 'commission', 'resend']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>For Resending</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['orders', 'commission', 'replaced']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Replaced Commissions</p>
              </a>
            </li>
          </ul>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" [routerLink]="['/orders', 'pin']" replaceUrl="true">
            <i class="nav-icon fas fa-barcode"></i>
            <p>Pins</p>
          </a>
        </li> -->
        <li class="nav-item has-treeview">
          <a class="nav-link" href="#">
            <i class="nav-icon fas fa-barcode"></i>
            <p>
              Pins
              <i class="fas fa-angle-right right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['orders', 'pins', 'pending']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Pending Pins</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['orders', 'pins', 'pushed']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Pushed Pins</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['orders', 'pins', 'generated']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Generated Pins</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['orders', 'pins', 'registered']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Registered Pins</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['orders', 'pins', 'cancelled']" replaceUrl="true" class="nav-link">
                <i class="fas fa-layer-group nav-icon"></i>
                <p>Cancelled Pins</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>