<div class="card">
	<div class="card-body">
		<div class="card-title">
			<h4>Order Information</h4>
		</div>

		<div class="float-right d-flex" *ngIf="showDropdown()">
			<div class="dropdown">
				<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
					data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					{{getStatus()}}
				</button>
				<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
					<a *ngFor="let stat of getNextStatuses(getStatus())" class="dropdown-item"
						(click)="openStatusForm($event, stat)" href="#">{{stat}}</a>
				</div>
			</div>
			<div class="infoDialog">
				<button mat-icon-button aria-label="Status Information" type="button" (click)="openStatusInfoDialog()">
					<mat-icon>help_outline</mat-icon>
				</button>
			</div>
		</div>

		<div class="card-text">
			<div class="row">
				<div class="col-sm">
					<div class="row">
						<div class="col-sm">
							<strong>Order:</strong>
						</div>
						<div class="col-sm ml-4 ml-md-0">
							{{ disposition.orderId }}
						</div>
					</div>

					<div class="row">
						<div class="col-sm">
							<strong>Placed on:</strong>
						</div>
						<div class="col-sm ml-4 ml-md-0">
							{{ disposition.transactionDate | date }}
						</div>
					</div>

					<div class="row">
						<div class="col-sm">
							<strong>Customer Name:</strong>
						</div>
						<div class="col-sm ml-4 ml-md-0">
							{{ disposition.customerName }}
						</div>
					</div>
				</div>

				<div class="col-sm">
					<div class="row">
						<div class="col-sm">
							<strong>Origin:</strong>
						</div>
						<div class="col-sm ml-4 ml-md-0">
							{{ disposition.origin }}
						</div>
					</div>

					<div class="row">
						<div class="col-sm">
							<strong>Disposition Status:</strong>
						</div>
						<div class="col-sm ml-4 ml-md-0">
							{{ disposition.dispositionStatus }}
						</div>
					</div>

					<div class="row">
						<div class="col-sm">
							<strong>Payment Type:</strong>
						</div>
						<div class="col-sm ml-4 ml-md-0">
							{{ disposition.paymentType }}
						</div>
					</div>

					<div class="row">
						<div class="col-sm">
							<strong>Courier Type:</strong>
						</div>
						<div class="col-sm ml-4 ml-md-0">
							{{ disposition.courierType }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>