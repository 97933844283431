import {
  AfterViewInit,
  Component,
  DoCheck,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Commission } from 'src/app/models/commission';
import { Disposition } from 'src/app/models/disposition';
import { Pin } from 'src/app/models/pin';
import { ShopOrderInfo } from 'src/app/models/shop-order-info';
import { CommissionService } from 'src/app/utils/services/commission.service';
import { OmsService } from 'src/app/utils/services/oms.service';
import { ShopService } from 'src/app/utils/services/shop.service';
import { StatusInfoDialogComponent } from 'src/app/views/status-info-dialog/status-info-dialog.component';

@Component({
  selector: 'app-pin-details',
  templateUrl: './pin-details.component.html',
  styleUrls: ['./pin-details.component.scss'],
})
export class PinDetailsComponent implements OnInit {
  public isLoading = true;
  public dispositionId: number;
  public orderId: number;
  public disposition: Disposition;
  public commissions: Commission[];
  public pins: Pin[];
  public shopOrderInfo: ShopOrderInfo;
  public dataSource = new MatTableDataSource<Commission>([]);
  public displayedColumns: string[] = [
    'id',
    'commissionType',
    'amount',
    'state',
    'isSent',
    'remarks',
  ];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  @ViewChild(MatSort)
  public sort: MatSort;

  public isPinGenerationLoading: boolean;

  constructor(
    private readonly commissionService: CommissionService,
    private readonly shopService: ShopService,
    private readonly omsService: OmsService,
    private readonly activeRoute: ActivatedRoute,
    private readonly router: Router,
    public dialog: MatDialog
  ) {
    this.shopOrderInfo = new ShopOrderInfo();
    this.disposition = new Disposition();
  }

  ngOnInit(): void {
    this.pins = [];
    this.activeRoute.params.subscribe({
      next: (params) => {
        this.orderId = params.orderId;
        this.loadData();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  generatePin(event) {
    this.isPinGenerationLoading = true;
    console.log(this.disposition.id);
    this.omsService.generatePins(this.disposition.id).subscribe({
      next: (pins) => {
        this.router.navigate(['orders', 'pin', 'pending']);
      },
      error: (err) => {
        console.error(err);
        this.isPinGenerationLoading = false;
      },
    });
  }

  showGeneratePinButton() {
    return (
      this.pins.filter((pin) => {
        return pin.pinState !== 'PUSHED';
      }).length > 0
    );
  }

  openStatusInfoDialog() {
    const ref = this.dialog.open(StatusInfoDialogComponent, {});

    ref.afterClosed().subscribe({
      next: (result) => {
        console.log(result);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  loadData() {
    this.omsService.getDispositionByOrderId(this.orderId).subscribe({
      next: (order) => {
        this.dispositionId = order.dispositionDTO.id;
        this.disposition = order.dispositionDTO;
        this.commissions = order.commissionDTOList;
        
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
        this.omsService.getPins(this.dispositionId).subscribe({
          next: (pins) => {
            this.pins = pins;
          },
          error: (err) => {
            console.error('no pins');
            this.pins = [];
          },
        });
      },
    });
    this.shopService.getOrderInfo(this.orderId).subscribe({
      next: (shopOrderInfo) => {
        this.shopOrderInfo = shopOrderInfo;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
