import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Commission } from 'src/app/models/commission';
import { AuthService } from 'src/app/utils/services/auth.service';
import { CommissionService } from 'src/app/utils/services/commission.service';
import { CommisionFormComponent } from '../commision-form/commision-form.component';

const FIVE_SECONDS = 5000;

@Component({
  selector: 'app-commission-list',
  templateUrl: './commission-list.component.html',
  styleUrls: ['./commission-list.component.scss'],
})
export class CommissionListComponent implements OnInit {
  public commissions: Commission[];

  @Input()
  public dispositionId: number;

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  @ViewChild(MatSort)
  public sort: MatSort;

  @Input()
  public showAddCommissionButton = false;

  @Input()
  public showEditCommissionButton = false;

  @Output()
  public changed: EventEmitter<boolean> = new EventEmitter();

  public dataSource = new MatTableDataSource<Commission>([]);
  public displayedColumns: string[] = [
    'id',
    'commissionType',
    'amount',
    'state',
    'isSent',
    'remarks',
    'modifyButtons',
  ];

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private readonly authService: AuthService,
    private readonly commissionService: CommissionService
  ) {}

  ngOnInit(): void {
    // console.log(this.commissions);
    this.loadData();
  }

  clearData() {
    this.commissions = [];
  }

  isAdmin() {
    return this.authService.getUsername().toLowerCase() == 'admin';
  }

  private loadData() {
    this.commissionService
      .getCommissionsForDisposition(this.dispositionId)
      .subscribe({
        next: (commissions) => {
          this.commissions = commissions;
          this.setDataSource();
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  openAddCommissionForm() {
    const commission = new Commission();
    commission.dispositionId = this.dispositionId;
    commission.dispositionDTO = { id: this.dispositionId };
    const ref = this.dialog.open(CommisionFormComponent, {
      data: {
        title: 'Add Commission',
        process: 'add',
        commission: commission,
      },
    });

    ref.afterClosed().subscribe({
      next: (result) => {
        console.log(result);
        if (result) {
          this.commissionService.addCommission(result).subscribe({
            next: (comm) => {
              this.changed.emit(true);
              this.loadData();
            },
            error: (err) => {
              console.error(err);
              this.snackBar.open(err.error.message, 'OK', {
                duration: FIVE_SECONDS,
              });
            },
          });
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  openModifyCommissionForm(commission: Commission) {
    const ref = this.dialog.open(CommisionFormComponent, {
      data: {
        title: 'Edit Commission',
        process: 'edit',
        commission: { ...commission, remarks: '' },
      },
    });

    ref.afterClosed().subscribe({
      next: (result) => {
        console.log(result);
        if (result) {
          this.commissionService.modifyCommission(result).subscribe({
            next: (comm) => {
              this.changed.emit(true);
              this.loadData();
            },
            error: (err) => {
              console.error(err);
              this.snackBar.open(err.error.message, 'OK', {
                duration: FIVE_SECONDS,
              });
            },
          });
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  setDataSource() {
    this.dataSource = new MatTableDataSource<Commission>(
      this.commissions.sort((a, b) => 0 - (a.id - b.id))
    );
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      // this.sort.sort({
      //   id: 'id',
      //   start: 'desc',
      //   disableClear: true,
      // });
    }
  }
}
