<h2 mat-dialog-title>Status Information</h2>
<mat-dialog-content>
	<h3><strong>Payment Status:</strong></h3>
	<ul>
		<li><strong>PENDING</strong> - used to pause the process due to some unforeseen event that would not allow the
			user
			to further advance the order processing.</li>
		<li><strong>HOLD</strong> - should be used only if there are impending issues preventing the order to be
			processed
			and the pending status is too subtle.</li>
		<li><strong>APPROVED</strong> - apply this status once payment has been verified.</li>
		<li><strong>DECLINED</strong> - apply this if payment was not valid.</li>
		<li><strong>CLEARED</strong> - this will push the order to be processed for fulfillment.</li>
		<li><strong>CANCELLED</strong> - this will cancel the order.</li>
		<li><strong>RTS</strong> - return to sender will cancel the order and apply a charge to the vendor using the
			shipping amount to be deducted on their ewallet.</li>
	</ul>
	<h3><strong>Fulfillment Status:</strong></h3>
	<ul>
		<li><strong>PENDING</strong> - used to pause the process due to some unforeseen event that would not allow the
			user
			to further advance the order processing.</li>
		<li><strong>HOLD</strong> - should be used only if there are impending issues preventing the order to be
			processed
			and the pending status is too subtle.</li>
		<li><strong>COMPLETED</strong> - this means the order has been packaged and ready for shipping</li>
		<li><strong>SHIPPED</strong> - this means the order has been picked up by the courier and shipped to the
			customer.
		</li>
		<li><strong>CANCELLED</strong> - this will cancel the order.</li>
	</ul>
	<h3><strong>Commission:</strong></h3>
	<ul>
		<li><strong>CREDIT COMMISSION</strong> - this will push the commission listed to Prime and will eventually be
			credited to the vendor’s ewallet.</li>
	</ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>