import { SHIPPED } from './../../models/constants';
import { FulfillmentService } from './../../utils/services/fulfillment.service';
import { Fulfillment } from './../../models/fulfillment';
import { PaymentService } from './../../utils/services/payment.service';
import { Payment } from './../../models/payment';
import { Disposition } from './../../models/disposition';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-status-form',
  templateUrl: './status-form.component.html',
  styleUrls: ['./status-form.component.scss'],
})
export class StatusFormComponent implements OnInit {
  public remarks: string;
  public disposition: Disposition;
  public paymentType: string;
  public status: string;
  public payment: Payment;
  public fulfillment: Fulfillment;
  public currentStatus: string;
  public loading = false;
  constructor(
    public dialogRef: MatDialogRef<StatusFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly paymentService: PaymentService,
    private readonly fulfillmentService: FulfillmentService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.disposition = this.data.disposition;
    this.currentStatus = this.data.currentStatus.substring(
      this.disposition.dispositionStatus.length + 3
    );

    if (this.disposition.dispositionStatus.toLowerCase() === 'payment') {
      this.payment = new Payment();
      this.payment.status = this.data.status;
      this.payment.dispositionId = this.disposition.id;
      this.payment.active = true;
    } else if (
      this.disposition.dispositionStatus.toLowerCase() === 'fulfillment'
    ) {
      this.fulfillment = new Fulfillment();
      this.fulfillment.status = this.data.status;
      this.fulfillment.dispositionId = this.disposition.id;
      this.fulfillment.courierType = this.disposition.courierType;
    }
  }

  updateStatus(event) {
    this.loading = true;
    if (this.disposition.dispositionStatus.toLowerCase() === 'payment') {
      this.payment.remarks = this.remarks;
      this.paymentService.changeStatus(this.payment).subscribe({
        next: (newPayment) => {
          console.log(newPayment);
          this.loading = false;
          this.dialogRef.close(true);
        },
        error: (err) => {
          console.error(err);
          this.loading = false;
          this.showError(err.message);
          this.dialogRef.close();
        },
      });
    } else if (
      this.disposition.dispositionStatus.toLowerCase() === 'fulfillment'
    ) {
      this.fulfillment.remarks = this.remarks;
      this.fulfillmentService.changeStatus(this.fulfillment).subscribe({
        next: (fulfillment) => {
          console.log(fulfillment);
          this.loading = false;
          this.showMessage('Status update successful.');
          this.dialogRef.close(true);
        },
        error: (err) => {
          console.error(err);
          this.loading = false;
          this.showError(err.message);
          this.dialogRef.close();
        },
      });
    }
  }

  showError(err: string) {
    this.snackBar.open(err, 'OK', { duration: 2000 });
  }

  showMessage(msg: string) {
    this.snackBar.open(msg, 'OK', { duration: 2000 });
  }
}
