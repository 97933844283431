<div class="card">
	<div class="card-body">
		<div class="card-title">
			<h4>Status Log</h4>
		</div>
		<div class="card-text">
			<div class="">
				<table mat-table [dataSource]="dataSource" matSort disableClear="true" class="table">
					<ng-container matColumnDef="formattedProcess">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							Process
						</th>
						<td mat-cell *matCellDef="let log">
							{{ log.formattedProcess }}
						</td>
					</ng-container>

					<ng-container matColumnDef="formattedStatus">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
						<td mat-cell *matCellDef="let log">{{ log.formattedStatus }}</td>
					</ng-container>

					<ng-container matColumnDef="formattedLastModifiedDate">
						<th mat-header-cell *matHeaderCellDef mat-sort-header start="desc">
							Date Updated
						</th>
						<td mat-cell *matCellDef="let log">
							{{ log.formattedLastModifiedDate }}
						</td>
					</ng-container>

					<ng-container matColumnDef="remarks">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Remarks</th>
						<td mat-cell *matCellDef="let log">{{ log.remarks }}</td>
					</ng-container>

					<ng-container matColumnDef="createdBy">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
						<td mat-cell *matCellDef="let log">{{ log.createdBy }}</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
				</table>

				<mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
			</div>
		</div>
	</div>
</div>