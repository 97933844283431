<div class="card">
  <div class="card-body">
    <div class="card-title">
      <h4>Total Summary</h4>
    </div>

    <div class="card-text">
      <div class="row">
        <div class="col-sm">
          <strong>Subtotal:</strong>
        </div>
        <div class="col-sm ml-4 ml-md-0">
          {{ shopOrderInfo.TotalAmount | number: '1.2-2' }}
        </div>
      </div>

      <div class="row">
        <div class="col-sm">
          <strong>Shipping Fee:</strong>
        </div>
        <div class="col-sm ml-4 ml-md-0">
          {{ shopOrderInfo.ShippingFee | number: '1.2-2' }}
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm">
          <strong>Additional Charges:</strong>
        </div>
      </div>

      <div class="row ml-3" *ngFor="let charge of shopOrderInfo.OtherCharges">
        <div class="col-sm">
          <strong>{{ charge.Type }}</strong>
        </div>
        <div class="col-sm ml-4 ml-md-0">
          {{ charge.Amount | number: '1.2-2' }}
        </div>
      </div>

      <div class="row text-danger border-top border-bottom border-danger mt-2">
        <div class="col-sm">
          <strong>TOTAL:</strong>
        </div>
        <div class="col-sm ml-4 ml-md-0">
          {{ getGrandTotal() | number: '1.2-2' }}
        </div>
      </div>
    </div>
  </div>
</div>
