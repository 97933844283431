<div class="card">
  <div class="card-body">
    <div class="card-title">
      <h4>Pins</h4>
    </div>
    <div class="card-text">
      <div class="">
        <mat-table
          [dataSource]="dataSource"
          matSort
          disableClear="true"
          class="table"
          aria-describedby="pins list"
        >
          <!-- Position Column -->
          <ng-container matColumnDef="entryType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Entry Type</mat-header-cell>
            <mat-cell *matCellDef="let log">
              {{ log.entryType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="packageType">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Package Type</mat-header-cell>
            <mat-cell *matCellDef="let log">
              {{ log.packageType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="controlNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Control Number</mat-header-cell>
            <mat-cell *matCellDef="let log">
              {{ log.controlNumber }}
            </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="pinState">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Pin State</mat-header-cell>
            <mat-cell *matCellDef="let log">{{ log.pinState }}</mat-cell>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="referrerId">
            <mat-header-cell *matHeaderCellDef mat-sort-header start="desc">
              Referrer ID
            </mat-header-cell>
            <mat-cell *matCellDef="let log">
              {{ log.referrerId }}
            </mat-cell>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="remarks">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Remarks</mat-header-cell>
            <mat-cell *matCellDef="let log">{{ log.remarks }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>

        <mat-paginator
          [pageSizeOptions]="[10, 15, 20]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>
