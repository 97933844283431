<h2>{{ title }}</h2>

<div class="table-responsive">
  <table class="table">
    <tr>
      <th>Order ID</th>
      <th>Payment ID</th>
      <th>Payment Type</th>
      <th>Date</th>
      <th>Payment Status</th>
    </tr>

    <tr *ngFor="let payment of payments; let index = index">
      <td>
        <a [routerLink]="['/orders', 'details', payment.dispositionId]">{{
          payment.dispositionId
        }}</a>
      </td>
      <td>{{ payment.id }}</td>
      <td>{{ payment.paymentType }}</td>
      <td>{{ payment.createdDate }}</td>
      <td>{{ payment.status }}</td>
    </tr>
  </table>
</div>
