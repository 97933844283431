import { Router } from '@angular/router';
import { AuthService } from 'src/app/utils/services/auth.service';
import { CommissionService } from './../../utils/services/commission.service';
import {
  PENDING,
  HOLD,
  CLEARED,
  CANCELLED,
  COMPLETED,
  SHIPPED,
  DECLINED,
  APPROVED,
  CREDIT_COMMISSION,
  RTS,
} from './../../models/constants';
import { Fulfillment } from './../../models/fulfillment';
import { Payment } from './../../models/payment';
import { StatusFormComponent } from './../status-form/status-form.component';
import { Disposition } from './../../models/disposition';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { sortBy } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StatusInfoDialogComponent } from '../status-info-dialog/status-info-dialog.component';

@Component({
  selector: 'app-order-information',
  templateUrl: './order-information.component.html',
  styleUrls: ['./order-information.component.scss'],
})
export class OrderInformationComponent implements OnInit {
  @Input()
  public disposition: Disposition;

  @Input()
  public location: string;

  @Input()
  public paymentType: string;

  @Input()
  public payments: Payment[];

  @Input()
  public fulfillments: Fulfillment[];

  public isCommissionLoading = false;

  public status: string;

  constructor(
    public dialog: MatDialog,
    @Inject(CommissionService)
    private commissionService: CommissionService,
    private readonly snackBar: MatSnackBar,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    if (
      (this.isCancelled() || this.isClosed()) &&
      this.hasPermissionToViewOrder()
    ) {
      return;
    }

    if (this.isForCommission() && this.hasPermissionToViewCommission()) {
      return;
    }

    if (
      !this.authService.hasPermission(
        this.disposition.dispositionStatus?.toLowerCase(),
        'view'
      )
    ) {
      this.snackBar.open('You are not permitted to do this action', 'OK', {
        duration: 2000,
      });
      window.history.back();
    }
  }

  isCancelled() {
    return this.disposition.dispositionStatus?.toLowerCase() === 'cancelled';
  }

  isClosed() {
    return this.disposition.dispositionStatus?.toLowerCase() === 'closed';
  }

  isForCommission() {
    return (
      this.disposition.dispositionStatus?.toLowerCase() === 'closed' &&
      this.disposition.showOnCreditCommission === true
    );
  }

  hasPermissionToChangeStatus() {
    if (this.isForCommission()) {
      return this.hasPermissionToEditCommision();
    }

    return this.authService.hasPermission(
      this.disposition.dispositionStatus.toLowerCase(),
      'edit'
    );
  }

  byCreatedDate(payment: Payment) {
    return payment.createdDate;
  }

  getStatus() {
    if (this.disposition.dispositionStatus.toLowerCase() === 'payment') {
      // console.log('Payments: ', this.payments);
      if (this.payments && this.payments.length > 0) {
        const sortedPayments = sortBy(this.payments, [this.byCreatedDate]);
        const payment = sortedPayments.slice(-1).pop();
        if (payment) {
          return `PAYMENT - ${payment.status}`;
        }
      }
      return 'PAYMENT';
    } else if (
      this.disposition.dispositionStatus.toLowerCase() === 'fulfillment'
    ) {
      if (this.fulfillments) {
        const sortedFulfillemnts = sortBy(this.fulfillments, [
          (f) => f.createdDate,
        ]);

        const fulfillment = sortedFulfillemnts.slice(-1).pop();
        if (fulfillment) {
          return `FULFILLMENT - ${fulfillment.status}`;
        }
      }
      return 'FULFILLMENT';
    } else {
      return this.disposition.dispositionStatus;
    }
  }

  getNextStatuses(currentStatus: string) {
    const statuses = {
      COD: this.getCodFlow(),
      COP: this.getCodFlow(),
      CREDITCARD: this.getCreditCardFlow(),
      PAYPAL: this.getCreditCardFlow(),
      ONLINEPAYMENT: this.getCreditCardFlow(),
    };
    return statuses[this.paymentType][currentStatus];
  }

  private getCreditCardFlow() {
    return {
      PAYMENT: [HOLD, PENDING, APPROVED, DECLINED, CANCELLED],
      'PAYMENT - HOLD': this.getStatusesAfterPaymentHoldCC(),
      'PAYMENT - PENDING': this.getStatusesAfterPaymentPendingCC(),
      'PAYMENT - CLEARED': [HOLD, APPROVED, DECLINED, CANCELLED],
      'PAYMENT - APPROVED': [HOLD, PENDING, CLEARED, CANCELLED],
      FULFILLMENT: [HOLD, PENDING, COMPLETED, CANCELLED],
      'FULFILLMENT - HOLD': this.getStatusesAfterFulfillmentHold(),
      'FULFILLMENT - PENDING': this.getStatusesAfterFulfillmentPending(),
      'FULFILLMENT - COMPLETED': [HOLD, PENDING, SHIPPED, CANCELLED],
      'FULFILLMENT - SHIPPED': [HOLD, COMPLETED, APPROVED, DECLINED, CANCELLED],
    };
  }

  private getCodFlow() {
    return {
      PAYMENT: [HOLD, PENDING, CLEARED, CANCELLED],
      'PAYMENT - HOLD': this.getStatusesAfterPaymentHoldCod(),
      'PAYMENT - PENDING': this.getStatusesAfterPaymentPendingCod(),
      'PAYMENT - CLEARED': [HOLD, APPROVED, RTS, DECLINED, CANCELLED],
      FULFILLMENT: [HOLD, PENDING, COMPLETED, CANCELLED],
      'FULFILLMENT - PENDING': this.getStatusesAfterFulfillmentPending(),
      'FULFILLMENT - HOLD': this.getStatusesAfterFulfillmentHold(),
      'FULFILLMENT - COMPLETED': [HOLD, PENDING, SHIPPED, CANCELLED],
      'FULFILLMENT - SHIPPED': [HOLD, COMPLETED, APPROVED, DECLINED, CANCELLED],
    };
  }

  getStatusesAfterPaymentHoldCod(): string[] {
    if (this.isFulfillmentShipped()) {
      return [PENDING, APPROVED, RTS, DECLINED, CANCELLED];
    }

    return [PENDING, RTS, CLEARED, CANCELLED];
  }

  getStatusesAfterPaymentPendingCod(): string[] {
    if (this.isFulfillmentShipped()) {
      return [HOLD, APPROVED, RTS, DECLINED, CANCELLED];
    }

    return [HOLD, CLEARED, CANCELLED];
  }

  getStatusesAfterPaymentHoldCC(): string[] {
    if (this.isPaymentApproved()) {
      return [PENDING, CLEARED, RTS, CANCELLED];
    }

    if (this.isFulfillmentShipped()) {
      return [PENDING, APPROVED, RTS, DECLINED, CANCELLED];
    }

    return [PENDING, APPROVED, DECLINED, CANCELLED];
  }

  getStatusesAfterPaymentPendingCC(): string[] {
    if (this.isPaymentApproved()) {
      return [HOLD, CLEARED, CANCELLED];
    }

    return [HOLD, APPROVED, DECLINED, CANCELLED];
  }

  getStatusesAfterFulfillmentHold(): string[] {
    if (this.isFulfillmentShipped()) {
      return [COMPLETED, CANCELLED];
    }

    if (this.isFulfillmentCompleted()) {
      return [PENDING, SHIPPED, CANCELLED];
    }
    return [PENDING, COMPLETED, CANCELLED];
  }

  getStatusesAfterFulfillmentPending(): string[] {
    if (this.isFulfillmentShipped()) {
      return [HOLD, CANCELLED];
    }

    if (this.isFulfillmentCompleted()) {
      return [HOLD, SHIPPED, CANCELLED];
    }

    return [HOLD, COMPLETED, CANCELLED];
  }

  isFulfillmentCompleted(): boolean {
    if (this.fulfillments) {
      const fulfillments = this.fulfillments.filter(
        (fulfillment: Fulfillment, index: number) => {
          return fulfillment.status === COMPLETED;
        }
      );

      return fulfillments.length > 0;
    }
    return false;
  }

  isFulfillmentShipped(): boolean {
    if (this.fulfillments) {
      const fulfillments = this.fulfillments.filter(
        (fulfillment: Fulfillment, index: number) => {
          return fulfillment.status === SHIPPED;
        }
      );

      return fulfillments.length > 0;
    }
    return false;
  }

  isPaymentCleared(): boolean {
    if (this.payments) {
      const payments = this.payments.filter(
        (payment: Payment, index: number) => {
          return payment.status === CLEARED;
        }
      );

      return payments.length > 0;
    }
    return false;
  }

  isPaymentApproved(): boolean {
    if (this.payments) {
      const payments = this.payments.filter(
        (payment: Payment, index: number) => {
          return payment.status === APPROVED;
        }
      );

      return payments.length > 0;
    }
    return false;
  }

  hasPermissionToViewCommission(): boolean {
    return this.authService.hasPermission('commission', 'view');
  }

  hasPermissionToEditCommision(): boolean {
    return this.authService.hasPermission('commission', 'edit');
  }

  hasPermissionToViewOrder(): boolean {
    return this.authService.hasPermission('orders', 'view');
  }

  showDropdown() {
    if (!this.hasPermissionToChangeStatus()) {
      return false;
    }

    if (
      this.disposition.dispositionStatus.toLowerCase() === 'payment' ||
      this.disposition.dispositionStatus.toLowerCase() === 'fulfillment'
    ) {
      return true;
    }

    return false;
  }

  getTitle() {
    return this.disposition.dispositionStatus;
  }

  creditCommission(event) {
    this.isCommissionLoading = true;
    this.commissionService.sendCommission(this.disposition.id).subscribe({
      next: (commission) => {
        console.log(commission);
        this.isCommissionLoading = false;
        window.location.reload();
      },
      error: (err) => {
        console.error(err);
        this.isCommissionLoading = false;
      },
    });
  }

  openStatusInfoDialog() {
    const ref = this.dialog.open(StatusInfoDialogComponent, {});

    ref.afterClosed().subscribe({
      next: (result) => {
        console.log(result);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  openStatusForm(event: any, status: string) {
    event.preventDefault();
    const ref = this.dialog.open(StatusFormComponent, {
      data: {
        status,
        currentStatus: this.getStatus(),
        disposition: this.disposition,
        paymentType: this.paymentType,
      },
    });

    ref.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          window.location.reload();
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
