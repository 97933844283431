import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { AuthService } from '../../../utils/services/auth.service';
import * as AdminLte from 'admin-lte';
import * as $ from 'jquery';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss'],
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
  @ViewChild('mainSidebar', { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();
  constructor(public authService: AuthService) {}

  ngOnInit() {
    AdminLte.Layout._jQueryInterface.call($('body'));
    AdminLte.PushMenu._jQueryInterface.call($('[data-widget="pushmenu"]'));
  }

  ngAfterViewInit() {
    this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
  }
}
