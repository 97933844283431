import { Fulfillment } from './../../models/fulfillment';
import { FulfillmentService } from './../../utils/services/fulfillment.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-fulfillments',
  templateUrl: './fulfillments.component.html',
  styleUrls: ['./fulfillments.component.scss'],
})
export class FulfillmentsComponent implements OnInit {
  public fulfillments: Fulfillment[];
  public title = 'All Fulfillments';
  public navigationSubscription: Subscription;
  public dataSubscription: Subscription;
  constructor(
    private readonly fulfillmentService: FulfillmentService,
    private readonly activeRoute: ActivatedRoute,
    private readonly router: Router
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.loadData();
      }
    });
  }

  loadData() {
    this.fulfillments = [];
    this.dataSubscription = this.activeRoute.params
      .pipe(
        map((params: Params) => {
          console.log(params);
          return `${params['path']}`;
        }),
        mergeMap((path) => {
          console.log(path);
          this.title = this.getTitle(path);
          return this.fulfillmentService.getFulfillmentsList(path);
        })
      )
      .subscribe(
        (fulfillments) => {
          this.fulfillments = fulfillments;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  ngOnInit(): void {}

  getTitle(path: string) {
    switch (path) {
      case 'all':
        return 'All Fulfillments';
      case 'payment':
        return 'Orders - Fulfillment';
      default:
        break;
    }
  }
}
