import { Component, Input, OnInit } from '@angular/core';
import { AdditionalCharge } from 'src/app/models/additional-charge';
import { Disposition } from '../../models/disposition';
import { ShopOrderInfo } from '../../models/shop-order-info';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnInit {
  @Input()
  public disposition: Disposition;
  @Input()
  public shopOrderInfo: ShopOrderInfo;
  constructor() {}

  ngOnInit(): void {}

  getGrandTotal() {
    return (
      this.shopOrderInfo.TotalAmount +
      this.shopOrderInfo.ShippingFee +
      this.getTotalAdditionalCharges()
    );
  }

  getTotalAdditionalCharges() {
    if (this.shopOrderInfo.OtherCharges) {
      return this.shopOrderInfo.OtherCharges.reduce(
        (total: number, charge: AdditionalCharge) => {
          total += charge.Amount;
          return total;
        },
        0
      );
    }
    return 0;
  }
}
