import { Disposition } from './../../models/disposition';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Order } from '../../models/order';
import { AuthService } from './auth.service';
import { Pin } from 'src/app/models/pin';

@Injectable({
  providedIn: 'root',
})
export class OmsService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService
  ) {}

  getOrders(subpath: string = 'all') {
    const url = `${environment.oms_service}/order/${subpath}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Order[]>(url, { headers });
  }

  getDispositions(subpath: string = 'all') {
    const url = `${environment.oms_service}/order/${subpath}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Disposition[]>(url, { headers });
  }

  getDispositionsByStatus(status: string) {
    const url = `${environment.oms_service}/order/status/${status}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Disposition[]>(url, { headers });
  }

  getRtsDispositionsByStatus(status: string) {
    const url = `${environment.oms_service}/order/rts`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Disposition[]>(url, { headers });
  }

  getPinsByStatus(status: string = 'PENDING') {
    const url = `${environment.oms_service}/order/pin/${status}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Disposition[]>(url, { headers });
  }

  getCommisionByStatus(status: string = 'PENDING') {
    const url = `${environment.oms_service}/order/commission/${status}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Disposition[]>(url, { headers });
  }

  getPins(dispositionId: number) {
    const url = `${environment.oms_service}/pin/list/${dispositionId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Pin[]>(url, { headers });
  }

  generatePins(dispositionId: number) {
    const url = `${environment.oms_service}/pin/generate/${dispositionId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Pin[]>(url, { headers });
  }

  getDispositionById(dispositionId: number) {
    const url = `${environment.oms_service}/order/disposition/${dispositionId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Order>(url, { headers });
  }

  getDispositionByOrderId(orderId: number) {
    const url = `${environment.oms_service}/order/${orderId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Order>(url, { headers });
  }

  getOrderDetails(orderId: number) {
    const url = `${environment.oms_service}/order/${orderId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Order>(url, { headers });
  }
}
