import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Commission } from 'src/app/models/commission';
import { CommissionService } from 'src/app/utils/services/commission.service';

@Component({
  selector: 'app-commision-form',
  templateUrl: './commision-form.component.html',
  styleUrls: ['./commision-form.component.scss'],
})
export class CommisionFormComponent implements OnInit {
  @Input()
  public dispositionId: number;

  public commission: Commission;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly commissionService: CommissionService
  ) {}

  ngOnInit(): void {
    if (this.data.commission) {
      this.commission = {...this.data.commission};
    } else {
      this.commission = new Commission();
    }
  }

  submitForm() {
    this.commissionService
      .addCommission(this.commission)
      .subscribe((response) => {
        console.log('add com:', response);
      });
  }
}
