import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Fulfillment } from 'src/app/models/fulfillment';
import { Log } from 'src/app/models/log';
import { Pin } from 'src/app/models/pin';
import { OmsService } from 'src/app/utils/services/oms.service';

@Component({
  selector: 'app-pin-list',
  templateUrl: './pin-list.component.html',
  styleUrls: ['./pin-list.component.scss'],
})
export class PinListComponent implements OnInit {
  @Input()
  public dispositionId: number;
  public pins: Pin[] = [];
  public orderId: number;
  public fulfillments: Fulfillment[];
  public logs: Log[];
  public dataSource = new MatTableDataSource<Log>([]);
  public displayedColumns: string[] = [
    'entryType',
    'packageType',
    'controlNumber',
    'pinState',
    'referrerId',
    'remarks',
  ];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  @ViewChild(MatSort)
  public sort: MatSort;

  constructor(private readonly omsService: OmsService) {}

  ngOnInit(): void {
    this.omsService.getPins(this.dispositionId).subscribe({
      next: (pins) => {
        this.pins = pins;
        this.setDataSource();
      },
      error: (err) => {
        console.error('no pins');
        this.pins = [];
      },
    });
  }

  setDataSource() {
    this.dataSource = new MatTableDataSource<Log>(this.pins);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.sort) {
      this.sort.sort({
        id: 'pinState',
        start: 'desc',
        disableClear: true,
      });
    }
  }

  getStatus(log: Log) {
    return log.status ? log.status : log.fulfillmentStatus;
  }
}
