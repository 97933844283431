import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
  @Input()
  public username: string;

  @Input()
  public width = '33.6px';

  public height: string;

  constructor() {}

  ngOnInit(): void {
    this.height = this.width;
  }
}
