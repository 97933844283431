export const PENDING = 'PENDING';
export const HOLD = 'HOLD';
export const CLEARED = 'CLEARED';
export const CANCELLED = 'CANCELLED';
export const COMPLETED = 'COMPLETED';
export const SHIPPED = 'SHIPPED';
export const APPROVED = 'APPROVED';
export const DECLINED = 'DECLINED';
export const RTS = 'RTS';
export const CREDIT_COMMISSION = 'CREDIT_COMMISSION';
