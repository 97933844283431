import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Fulfillment } from '../../models/fulfillment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class FulfillmentService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService
  ) {}

  getFulfillmentsList(subpath: string = 'all') {
    const url = `${environment.oms_service}/fulfillment/${subpath}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Fulfillment[]>(url, { headers });
  }

  getDispositionFulfillments(dispositionId: number) {
    const url = `${environment.oms_service}/fulfillment/list/${dispositionId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Fulfillment[]>(url, { headers });
  }

  getFulfillmentDetails(fulfillmentId: number) {
    const url = `${environment.oms_service}/fulfillment/${fulfillmentId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Fulfillment>(url, { headers });
  }

  changeStatus(fulfillment: Fulfillment) {
    const url = `${environment.oms_service}/fulfillment/new`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<Fulfillment>(url, fulfillment, { headers });
  }
}
