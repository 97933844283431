import { CommissionDetailsComponent } from './pages/commission-details/commission-details.component';
import { FulfillmentsComponent } from './pages/fulfillments/fulfillments.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { ActiveOrdersComponent } from './pages/active-orders/active-orders.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { BlankComponent } from './views/blank/blank.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './views/profile/profile.component';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AuthGuard } from './utils/guards/auth.guard';
import { NonAuthGuard } from './utils/guards/non-auth.guard';
import { PaymentsComponent } from './pages/payments/payments.component';
import { PinDetailsComponent } from './pages/pin-details/pin-details.component';
import { OrdersPinsComponent } from './pages/orders-pins/orders-pins.component';
import { OrdersCommissionComponent } from './pages/orders-commission/orders-commission.component';
import { OrdersRtsComponent } from './pages/orders-rts/orders-rts.component';
import { RtsDetailsComponent } from './pages/rts-details/rts-details.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'blank',
        component: BlankComponent,
      },
      {
        path: '',
        // component: DashboardComponent,
        redirectTo: '/orders/all',
        pathMatch: 'full',
      },
      {
        path: 'orders',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
          {
            path: 'pins/:status',
            component: OrdersPinsComponent,
          },
          {
            path: 'commission/:status',
            component: OrdersCommissionComponent,
          },
          {
            path: 'rts',
            component: OrdersRtsComponent,
          },
          {
            path: 'details/:dispId/payment/:paymentId',
            component: OrderDetailsComponent,
          },
          {
            path: 'details/:dispId/fulfillment/:fulfillmentId',
            component: OrderDetailsComponent,
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'details/:orderId',
            component: OrderDetailsComponent,
          },
          {
            path: ':path/:status',
            component: ActiveOrdersComponent,
            runGuardsAndResolvers: 'always',
          },
          {
            path: ':status',
            component: ActiveOrdersComponent,
            runGuardsAndResolvers: 'always',
          },
        ],
      },
      {
        path: 'payments',
        //component: ActiveOrdersComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
          {
            path: ':path',
            component: PaymentsComponent,
            runGuardsAndResolvers: 'always',
          },
        ],
      },
      {
        path: 'fulfillments',
        //component: ActiveOrdersComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
          {
            path: ':path',
            component: FulfillmentsComponent,
            runGuardsAndResolvers: 'always',
          },
        ],
      },
      {
        path: 'commission',
        //component: ActiveOrdersComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
          {
            path: 'details/:orderId',
            component: CommissionDetailsComponent,
          },
          {
            path: ':path',
            component: PaymentsComponent,
            runGuardsAndResolvers: 'always',
          },
        ],
      },
      {
        path: 'rts',
        //component: ActiveOrdersComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
          {
            path: 'details/:orderId',
            component: RtsDetailsComponent,
          },
          {
            path: ':path',
            component: PaymentsComponent,
            runGuardsAndResolvers: 'always',
          },
        ],
      },
      {
        path: 'pin',
        //component: ActiveOrdersComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
          {
            path: 'details/:orderId',
            component: PinDetailsComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NonAuthGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
