import { Permission } from './../../models/permission';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import jwtDecode, { JwtPayload } from 'jwt-decode';

interface TokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user = {
    firstName: 'Alexander',
    lastName: 'Pierce',
    image: 'assets/img/user2-160x160.jpg',
  };

  constructor(private router: Router, private httpClient: HttpClient) {}

  login(params: URLSearchParams) {
    const url = `${environment.idp}/oauth/token`;

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient
      .post<TokenResponse>(url, params.toString(), {
        headers,
      })
      .pipe(
        tap((response) => {
          const token = jwtDecode<JwtPayload>(response.access_token);
          const date = new Date(token.exp * 1000);
          localStorage.setItem('username', token['unique_name']);
          localStorage.setItem('role', token['role']);
          localStorage.setItem('token', response.access_token);
          localStorage.setItem('token_expiry', date.toLocaleString());
        })
      );
  }

  getUserPermissions(): Permission[] {
    const stringPermissions = localStorage.getItem('permissions');
    if (stringPermissions) {
      return JSON.parse(stringPermissions) as Permission[];
    }

    return [];
  }

  hasPermission(page: string, action: string): boolean {
    const perms = this.getUserPermissions().filter((perm: Permission) => {
      return perm.name?.toLowerCase() === `${page?.toLowerCase()}, ${action?.toLowerCase()}`;
    });
    return perms.length > 0;
  }

  getOnlineUserPermissions() {
    const url = `${environment.idp}/api/oms/permissions`;
    const headers = {
      Authorization: `Bearer ${this.getAuthToken()}`,
    };
    return this.httpClient.get<Permission[]>(url, { headers });
  }

  public getTokenExpiryDate(): any {
    if (this.isLoggedIn()) {
      return new Date(localStorage.getItem('token_expiry'));
    }
    return new Date(Date.now());
  }

  isLoggedIn() {
    const token = localStorage.getItem('token');
    if (token) {
      return true;
    }

    return false;
  }

  getAuthToken(): string {
    if (this.isLoggedIn()) {
      return localStorage.getItem('token');
    }
    return null;
  }

  getUsername(): string {
    if (this.isLoggedIn()) {
      return localStorage.getItem('username');
    }
    return null;
  }

  getRole(): string {
    if (this.isLoggedIn()) {
      return localStorage.getItem('role');
    }
    return null;
  }

  register() {
    localStorage.setItem('token', 'LOGGED_IN');
    this.router.navigate(['/']);
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
