<h2>{{ title }}</h2>

<div class="table-responsive">
  <table class="table">
    <tr>
      <th>Order ID</th>
      <th>Fulfillment ID</th>
      <th>Courier Type</th>
      <th>Date</th>
      <th>Fulfillment Status</th>
    </tr>

    <tr *ngFor="let fulfillment of fulfillments; let index = index">
      <td>
        <a [routerLink]="['/orders', 'details/fulfillment', fulfillment.id]">{{
          fulfillment.dispositionId
        }}</a>
      </td>
      <td>{{ fulfillment.id }}</td>
      <td>{{ fulfillment.courierType }}</td>
      <td>{{ fulfillment.createdDate }}</td>
      <td>{{ fulfillment.fulfillmentStatus }}</td>
    </tr>
  </table>
</div>
