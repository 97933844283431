import { MatSnackBar } from '@angular/material/snack-bar';
import { Disposition } from './../../models/disposition';
import {
  AfterViewInit,
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { OmsService } from '../../utils/services/oms.service';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { Subscription, throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { formatDate } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from '../../utils/services/auth.service';

@Component({
  selector: 'app-orders-rts',
  templateUrl: './orders-rts.component.html',
  styleUrls: ['./orders-rts.component.scss'],
})
export class OrdersRtsComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'orderId',
    'origin',
    'transactionDate',
    'formattedDispositionStatus',
    'courierType',
    'paymentType',
    'customerName',
    'distributorId',
  ];
  public dispositions: Disposition[];
  public title = 'All Orders';
  public isLoading = true;
  public navigationSubscription: Subscription;
  public dataSubscription: Subscription;
  public dataSource = new MatTableDataSource<Disposition>([]);

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  @ViewChild(MatSort)
  public sort: MatSort;

  constructor(
    private readonly omsService: OmsService,
    private readonly activeRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar
  ) {
    this.navigationSubscription = this.router.events.subscribe({
      next: (e: any) => {
        // If it is a NavigationEnd event re-initalise the component
        if (e instanceof NavigationEnd) {
          this.loadData();
        }
      },
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  loadData() {
    this.isLoading = true;
    this.dispositions = [];
    this.destroySubscription();
    this.dataSubscription = this.activeRoute.params
      .pipe(
        map((params: Params) => {
          return `${params['status']}`;
        }),
        mergeMap((status) => {
          this.title = this.getTitle(status);
          if (!this.authService.hasPermission(this.getPage('pin'), 'view')) {
            this.snackBar.open(
              'You are not permitted to do this action',
              'OK',
              {
                duration: 2000,
              }
            );
            return throwError(
              new Error('You are not permitted to do this action')
            );
          }
          return this.omsService.getRtsDispositionsByStatus(status.toUpperCase());
        })
      )
      .subscribe({
        next: (dispositions) => {
          this.dispositions = dispositions;
          this.setDataSource(dispositions);
          this.isLoading = false;
        },
        error: (err) => {
          console.error(err);
          this.dispositions = [];
          this.setDataSource(this.dispositions);
          this.isLoading = false;
        },
      });
  }

  private setDataSource(dispositions: Disposition[]) {
    this.dataSource = new MatTableDataSource<Disposition>(
      dispositions.map((disp) => {
        disp.formattedTransactionDate = formatDate(
          disp.transactionDate,
          'medium',
          'EN'
        );
        disp.formattedDispositionStatus =
          disp.showOnCreditCommission &&
          disp.dispositionStatus.toLowerCase() == 'closed'
            ? 'COMMISSION'
            : disp.dispositionStatus;
        return disp;
      })
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.sort) {
      this.sort.sort({
        id: 'transactionDate',
        start: 'desc',
        disableClear: true,
      });
    }
  }

  destroySubscription() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    this.destroySubscription();
  }

  ngOnInit(): void {}

  getPage(path: string) {
    switch (path) {
      case 'all':
        return 'orders';
      case 'payment':
        return 'payment';
      case 'fulfillment':
        return 'fulfillment';
      case 'commission':
        return 'commission';
      case 'pin':
        return 'pin';
      default:
        break;
    }
  }

  getTitle(status: string) {
    switch (status) {
      case 'pending':
        return 'Pending RTS';
      case 'released':
        return 'Released RTS';
      case 'registered':
        return 'Registered Pins';
      case 'commission':
        return 'Orders - Commission';
      case 'pin':
        return 'Orders - Pins';
      default:
        return 'RTS Orders';
    }
  }
}
