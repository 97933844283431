import { Disposition } from './../../models/disposition';
import { OmsService } from './../../utils/services/oms.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Commission } from './../../models/commission';
import { CommissionService } from './../../utils/services/commission.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ShopService } from '../../utils/services/shop.service';
import { ShopOrderInfo } from '../../models/shop-order-info';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { StatusInfoDialogComponent } from '../../views/status-info-dialog/status-info-dialog.component';

@Component({
  selector: 'app-rts-details',
  templateUrl: './rts-details.component.html',
  styleUrls: ['./rts-details.component.scss'],
})
export class RtsDetailsComponent implements OnInit {
  public isLoading = true;
  public dispositionId: number;
  public orderId: number;
  public disposition: Disposition;
  public commissions: Commission[];
  public shopOrderInfo: ShopOrderInfo;
  public dataSource = new MatTableDataSource<Commission>([]);
  public displayedColumns: string[] = [
    'id',
    'commissionType',
    'amount',
    'state',
    'isSent',
    'remarks',
  ];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  @ViewChild(MatSort)
  public sort: MatSort;

  public isCommissionLoading: boolean;

  constructor(
    private readonly commissionService: CommissionService,
    private readonly shopService: ShopService,
    private readonly omsService: OmsService,
    private readonly activeRoute: ActivatedRoute,
    private readonly router: Router,
    public dialog: MatDialog
  ) {
    this.shopOrderInfo = new ShopOrderInfo();
    this.disposition = new Disposition();
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe({
      next: (params) => {
        this.orderId = params.orderId;
        this.loadData();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  creditRts(event) {
    this.isCommissionLoading = true;
    this.commissionService.sendCommission(this.disposition.id).subscribe({
      next: (commission) => {
        console.log(commission);
        this.isCommissionLoading = false;
        this.router.navigate(['/orders', 'rts']);
      },
      error: (err) => {
        console.error(err);
        this.isCommissionLoading = false;
      },
    });
  }

  openStatusInfoDialog() {
    const ref = this.dialog.open(StatusInfoDialogComponent, {});

    ref.afterClosed().subscribe({
      next: (result) => {
        console.log(result);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  loadData() {
    this.omsService.getDispositionByOrderId(this.orderId).subscribe({
      next: (order) => {
        this.dispositionId = order.dispositionDTO.id;
        this.disposition = order.dispositionDTO;
        this.commissions = order.commissionDTOList;
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
    this.shopService.getOrderInfo(this.orderId).subscribe({
      next: (shopOrderInfo) => {
        this.shopOrderInfo = shopOrderInfo;
      },
      error: (err) => {
        console.error(err);
      },
    });
    // this.commissionService
    //   .getCommissionsForDisposition(this.dispositionId)
    //   .subscribe({
    //     next: (commissions) => {
    //       this.commissions = commissions;
    //       this.setDataSource();
    //     },
    //     error: (err) => {
    //       console.error(err);
    //     },
    //     complete: () => {
    //       this.isLoading = false;
    //     },
    //   });
  }

  setDataSource() {
    this.dataSource = new MatTableDataSource<Commission>(this.commissions);
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.sort({
        id: 'formattedLastModifiedDate',
        start: 'desc',
        disableClear: true,
      });
    }
  }
}
