export class Disposition {
  id?: number;
  orderId?: number;
  transactionDate?: Date;
  formattedTransactionDate?: string;
  showOnCreditCommission?: boolean;
  showOnPinGeneration?: boolean;
  origin?: string;
  productType?: string;
  entryType?: string;
  distributorId?: number;
  customerId?: string;
  customerName?: string;
  totalAmount?: number;
  paymentType?: string;
  paymentDetails?: string;
  courierType?: string;
  fulfillmentDetails?: string;
  dispositionStatus?: string;
  formattedDispositionStatus?: string;
  currency?: string;
  remarks?: string;
  active?: boolean;
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
}
