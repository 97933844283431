<div *ngIf="isLoading" class="loading">
  <div class="spinner-grow text-primary mr-2" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <span>Loading...</span>
</div>
<div [ngClass]="{'content': true, 'active': !isLoading}">
  <div *ngIf="errorMessage" class="alert alert-danger alert-dismissible fade show" role="alert">
    <strong>Error:</strong> Order with ID "{{ orderId }}" was not found
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="d-flex justify-content-between">
    <h2>{{ getTitle() }}</h2>
    <!-- <app-button
      (click)="showMessages($event)"
      [type]="'submit'"
      [block]="true"
      [text]="'Messages'"
      [loading]="isMessageLoading"
    >
    </app-button> -->
  </div>

  <app-order-information *ngIf="disposition.id && payments && fulfillments" [disposition]="disposition" [paymentType]="disposition.paymentType"
    [location]="disposition.dispositionStatus" [payments]="payments" [fulfillments]="fulfillments">
  </app-order-information>
  <app-vendor-information *ngIf="!isLoading" [disposition]="disposition" [shopOrderInfo]="shopOrderInfo"></app-vendor-information>
  <app-commission-list *ngIf="dispositionId" [dispositionId]="dispositionId" key="dispositionId"></app-commission-list>
  <app-pin-list *ngIf="dispositionId && disposition && disposition.origin == 'PCKGPH'" [dispositionId]="dispositionId"></app-pin-list>
  <app-order-items [disposition]="disposition" [shopOrderInfo]="shopOrderInfo"></app-order-items>
  <div class="row" *ngIf="!isLoading">
    <div class="col-md order-md-2">
      <app-order-summary [disposition]="disposition" [shopOrderInfo]="shopOrderInfo"></app-order-summary>
    </div>
    <div class="col-md order-md-1">
      <app-address title="Billing Address" [address]="shopOrderInfo.BillingAddress"></app-address>

      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <h4>Shipping Address</h4>
          </div>
          <div class="card-text">
            <div>
              <i class="fas fa-user fa-fw mr-2"></i>
              {{ shopOrderInfo.ShippingAddress?.FirstName }}
              {{ shopOrderInfo.ShippingAddress?.LastName }}
            </div>
            <div>
              <i class="fas fa-phone-alt fa-fw mr-2"></i>
              {{ shopOrderInfo.ShippingAddress?.PhoneNumber }}
            </div>
            <div>
              <i class="fas fa-envelope fa-fw mr-2"></i>
              {{ shopOrderInfo.ShippingAddress?.Email }}
            </div>
            <div>
              <i class="fas fa-map-marker fa-fw mr-2"></i>
              {{ shopOrderInfo.ShippingAddress?.Address1 }}
              {{ shopOrderInfo.ShippingAddress?.Address2 }}
              <p>
                {{ getAddress() }}
              </p>
            </div>
            <div>
              <strong>Fulfillment Details: </strong>
              {{ disposition.fulfillmentDetails }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <app-pin-list [orderId]="disposition.id"></app-pin-list> -->
  <app-log *ngIf="dispositionId" [orderId]="dispositionId"></app-log>
</div>