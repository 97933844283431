<div class="login-box">
	<div class="login-logo">
		<a [routerLink]="['/']"><b>OMS</b></a>
	</div>
	<!-- /.login-logo -->
	<div class="card">
		<div class="card-body login-card-body">
			<p class="login-box-msg">Sign in to start your session</p>

			<form [formGroup]="loginForm" (ngSubmit)="login()">
				<div class="input-group mb-3">
					<input formControlName="username" type="text" class="form-control" placeholder="Username" />
					<div class="input-group-append">
						<div class="input-group-text">
							<span class="fas fa-envelope"></span>
						</div>
					</div>
				</div>
				<div class="input-group mb-3">
					<input formControlName="password" type="password" class="form-control" placeholder="Password" />
					<div class="input-group-append">
						<div class="input-group-text">
							<span class="fas fa-lock"></span>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-7">
						<div class="icheck-primary">
							<input type="checkbox" id="remember" />
							<label for="remember"> Remember Me </label>
						</div>
					</div>
					<!-- /.col -->
					<div class="col-5">
						<app-button [type]="'submit'" [block]="true" [text]="'Sign In'" [loading]="isAuthLoading">
						</app-button>
					</div>
					<!-- /.col -->
				</div>
			</form>

			<p class="mb-1">
				<a [routerLink]="['/forgot-password']">I forgot my password</a>
			</p>
		</div>
		<!-- /.login-card-body -->
	</div>
</div>