import { FulfillmentService } from './../../utils/services/fulfillment.service';
import { PaymentService } from './../../utils/services/payment.service';
import { Fulfillment } from './../../models/fulfillment';
import { Payment } from './../../models/payment';
import { ShopOrderInfo } from './../../models/shop-order-info';
import { ShopService } from './../../utils/services/shop.service';
import { Order } from './../../models/order';
import { OmsService } from './../../utils/services/oms.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { mergeMap, tap } from 'rxjs/operators';
import { Disposition } from '../../models/disposition';
import { forkJoin, Observable, of } from 'rxjs';
import { Commission } from 'src/app/models/commission';
import { Address } from 'src/app/models/address';

interface IdParameters {
  orderId: Observable<number> | number;
  paymentId: Observable<number> | number;
  fulfillmentId: Observable<number> | number;
}

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  public orderId: number = 0;
  public paymentId: number = 0;
  public dispositionId = 0;
  public fulfillmentId: number = 0;
  public order: Order = new Order();
  public disposition: Disposition = new Disposition();
  public commissions: Commission[] = [];
  public errorMessage: string;
  public shopOrderInfo: ShopOrderInfo;
  public isLoading = true;
  public paymentDetails: Payment = new Payment();
  public fulfillmentDetails: Fulfillment = new Fulfillment();
  public payments: Payment[];
  public fulfillments: Fulfillment[] = [];
  public isMessageLoading = false;
  constructor(
    private readonly omsService: OmsService,
    private readonly activeRoute: ActivatedRoute,
    private readonly shopService: ShopService,
    private readonly paymentService: PaymentService,
    private readonly fulfillmentService: FulfillmentService,
    private readonly router: Router
  ) {
    this.shopOrderInfo = new ShopOrderInfo();
    this.shopOrderInfo.BillingAddress = new Address();
    this.shopOrderInfo.ShippingAddress = new Address();
  }

  ngOnInit(): void {
    this.order = new Order();
    this.disposition = new Disposition();
    this.order.adjustmentDTOList = [];
    this.order.commissionDTOList = [];
    this.payments = [];

    this.activeRoute.params
      .pipe(
        mergeMap((params: Params) => {
          return forkJoin(this.getIdParameters(params));
        }),
        this.retrieveDisposition(),
        mergeMap((details) => {
          return forkJoin({
            orderDetails: of(details),
            shopOrderInfo: this.shopService.getOrderInfo(
              details.dispositionDTO.orderId
            ), //this.shopService.getOrderInfo(4836),
          });
        })
      )
      .subscribe({
        next: (data: {
          orderDetails: Order;
          shopOrderInfo: ShopOrderInfo;
          payments: Payment[];
          fulfillments: Fulfillment[];
        }) => {
          this.setData(data);
        },
        error: (err) => {
          console.error(err);
          if (err.error.status === 404) {
            this.errorMessage = 'Order Id not found';
            this.isLoading = false;
          }
        },
      });
  }

  getTitle() {
    return this.disposition.dispositionStatus;
  }

  showMessages(event) {}

  getAddress() {
    const address = [];

    if (this.shopOrderInfo.ShippingAddress.City) {
      address.push(this.shopOrderInfo.ShippingAddress.City);
    }

    if (this.shopOrderInfo.ShippingAddress.State) {
      address.push(this.shopOrderInfo.ShippingAddress.State);
    }

    if (this.shopOrderInfo.ShippingAddress.Country) {
      address.push(this.shopOrderInfo.ShippingAddress.Country);
    }

    return address.join(', ');
  }

  private retrieveDisposition() {
    return mergeMap((ids: IdParameters) => {
      return this.omsService
        .getDispositionByOrderId(ids.orderId as number)
        .pipe(
          tap((order: Order) => {
            this.order = order;
            this.dispositionId = order.dispositionDTO.id;
            this.fulfillmentService
              .getDispositionFulfillments(order.dispositionDTO.id as number)
              .subscribe((fulfillments) => {
                this.fulfillments = fulfillments;
              });
            this.paymentService
              .getDispositionPayments(this.dispositionId as number)
              .subscribe((payments) => {
                this.payments = payments;
              });
          })
        );
    });
  }


  private setData(data: {
    orderDetails: Order;
    shopOrderInfo: ShopOrderInfo;
    payments: Payment[];
    fulfillments: Fulfillment[];
  }) {
    this.order = data.orderDetails;
    this.disposition = data.orderDetails.dispositionDTO;
    this.commissions = this.order.commissionDTOList;
    this.shopOrderInfo = data.shopOrderInfo;
    this.isLoading = false;
  }

  private getIdParameters(params: Params): IdParameters {
    return {
      orderId: of(params.orderId),
      paymentId: of(parseInt(params.paymentId, 10)),
      fulfillmentId: of(parseInt(params.fulfillmentId, 10)),
    };
  }
}
