<div class="ordersTitle">
  <h2>{{ title }}</h2>

  <div *ngIf="isLoading" class="loading">
    <div class="spinner-grow text-primary mr-2" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <span>Loading...</span>
  </div>
</div>

<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. DELIVERY" #input />
</mat-form-field>

<div [ngClass]="{'table-responsive':true, 'ordersTable': true, 'active': !isLoading}">
  <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="orderId">
      <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a [routerLink]="['/orders/details/', element.orderId]"
          *ngIf="title != 'Orders - Commission' && title != 'Orders - Pins'">{{ element.orderId }}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="orderIds">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Order ID
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.orderId }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="origin">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Origin
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.origin }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="transactionDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Transaction Date
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.formattedTransactionDate }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dispositionStatus">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Status
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.dispositionStatus }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="formattedDispositionStatus">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Status
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.formattedDispositionStatus }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="courierType">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Courier Type
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.courierType }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="paymentType">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Payment Type
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.paymentType }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Customer
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.customerName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="distributorId">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Vendor ID
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.distributorId }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalAmount">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Total Amount
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.totalAmount | number: '1.2-2' }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 15, 20]"></mat-paginator>
</div>