<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
    <div>
        <mat-form-field appearance="fill">
            <mat-label>Commission Type</mat-label>
            <select matNativeControl [(ngModel)]="commission.commissionType" [disabled]="data.process == 'edit'">
                <option value="REFERRAL">REFERRAL</option>
                <option value="MARKUP">MARKUP</option>
                <option value="UNOPRENEUR">UNOPRENEUR</option>
                <option value="UBC">UBC</option>
                <option value="DIRECTSELLING">DIRECTSELLING</option>
                <option value="NUCPOINT">NUCPOINT</option>
                <option value="RTS">RTS</option>
                <option value="RSREWARD">RSREWARD</option>
            </select>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field appearance="fill">
            <mat-label>Amount</mat-label>
            <input matInput [(ngModel)]="commission.amount">
        </mat-form-field>
    </div>

    <div>
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Remarks</mat-label>
            <textarea matInput [(ngModel)]="commission.remarks" placeholder="Ex. Adjusted manually"></textarea>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <app-button [mat-dialog-close]="commission" [type]="'button'" [block]="true" [text]="'Submit'"
        [loading]="false">
    </app-button>
</mat-dialog-actions>