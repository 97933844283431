<div class="card">
  <div class="card-body">
    <div class="card-title">
      <h4>Order Items</h4>
    </div>
    <table class="table">
      <tr>
        <th>Item</th>
        <th>Quantity</th>
        <th>Amount</th>
      </tr>

      <tr *ngFor="let item of shopOrderInfo.Items">
        <td>{{ item.Name }}</td>
        <td>{{ item.Quantity }}</td>
        <td>{{ item.Price | number: '1.2-2' }}</td>
      </tr>
    </table>
  </div>
</div>
