<h3>Change status to {{data.status}}</h3>
<div>
	<label for="remarks">Remarks:</label>
</div>
<div class="mb-2	">
	<textarea cols="30" rows="10"  [(ngModel)]="remarks"></textarea>
</div>

<div>
	<app-button (click)="updateStatus($event)" [type]="'submit'" [block]="true" [text]="'Submit'" [loading]="loading">
	</app-button>
</div>