<!-- Commission List Start -->
<div class="card">
    <div class="card-body">
        <div class="card-title">
            <h4>Commissions</h4>
        </div>

        <div class="card-title-button" *ngIf="showAddCommissionButton && isAdmin()">
            <app-button (click)="openAddCommissionForm()" [type]="'button'" [block]="true" [text]="'Add Commission'"
                [loading]="false">
            </app-button>
        </div>

        <table mat-table [dataSource]="dataSource" matSort disableClear="true" class="table">
            <!-- Position Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Commission ID
                </th>
                <td mat-cell *matCellDef="let commission">
                    {{ commission.id }}
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="commissionType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                <td mat-cell *matCellDef="let commission">{{ commission.commissionType }}</td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Amount
                </th>
                <td mat-cell *matCellDef="let commission">
                    {{ commission.currency }} {{ commission.amount }}
                </td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
                <td mat-cell *matCellDef="let commission">{{ commission.state }}</td>
            </ng-container>

            <ng-container matColumnDef="isSent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Sent</th>
                <td mat-cell *matCellDef="let commission">{{ commission.isSent }}</td>
            </ng-container>

            <ng-container matColumnDef="remarks">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Remarks</th>
                <td mat-cell *matCellDef="let commission">{{ commission.remarks }}</td>
            </ng-container>

            <ng-container matColumnDef="modifyButtons">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let commission">
                    <div style="height: 100%;">
                        <app-button style="display:inline-block;" *ngIf="showAddCommissionButton && isAdmin() && commission.state.toLowerCase() != 'replaced'"
                            (click)="openModifyCommissionForm(commission)" [type]="'button'" [block]="true"
                            [text]="'Modify'" [loading]="false">
                        </app-button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
    </div>
</div>
<!-- Commission List Start -->