<div *ngIf="isLoading" class="loading">
  <div class="spinner-grow text-primary mr-2" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <span>Loading...</span>
</div>

<div [ngClass]="{'content': true, 'active': !isLoading}">
  <div class="d-flex justify-content-between">
    <h2>Pins</h2>

    <div class="d-flex" *ngIf="disposition.showOnPinGeneration === true && showGeneratePinButton()">
      <app-button (click)="generatePin($event)" [type]="'submit'" [block]="true" [text]="'Generate Pin'"
        [loading]="isPinGenerationLoading">
      </app-button>
      <div class="infoDialog">
        <button mat-icon-button aria-label="Status Information" type="button" (click)="openStatusInfoDialog()">
          <mat-icon>help_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <app-order-items [disposition]="disposition" [shopOrderInfo]="shopOrderInfo"></app-order-items>

  <app-pin-list *ngIf="dispositionId" [dispositionId]="dispositionId"></app-pin-list>

  <div class="row">
    <div class="col-md">
      <!-- Order Information Start -->
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <h4>Order Information</h4>
          </div>

          <div class="card-text">
            <div class="row">
              <div class="col-sm">
                <div class="row">
                  <div class="col-sm">
                    <strong>Order:</strong>
                  </div>
                  <div class="col-sm ml-4 ml-md-0">
                    {{ disposition.orderId }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <strong>Placed on:</strong>
                  </div>
                  <div class="col-sm ml-4 ml-md-0">
                    {{ disposition.transactionDate | date }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <strong>Customer Name:</strong>
                  </div>
                  <div class="col-sm ml-4 ml-md-0">
                    {{ disposition.customerName }}
                  </div>
                </div>
              </div>

              <div class="col-sm">
                <div class="row">
                  <div class="col-sm">
                    <strong>Origin:</strong>
                  </div>
                  <div class="col-sm ml-4 ml-md-0">
                    {{ disposition.origin }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <strong>Disposition Status:</strong>
                  </div>
                  <div class="col-sm ml-4 ml-md-0">
                    {{ disposition.dispositionStatus }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <strong>Payment Type:</strong>
                  </div>
                  <div class="col-sm ml-4 ml-md-0">
                    {{ disposition.paymentType }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Order Information End -->
    </div>

    <div class="col-md">
      <app-order-summary [disposition]="disposition" [shopOrderInfo]="shopOrderInfo"></app-order-summary>
    </div>
  </div>

  <app-log  *ngIf="dispositionId" [orderId]="dispositionId"></app-log>
</div>