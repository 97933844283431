import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { AuthService } from '../../utils/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  public isAuthLoading = false;
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.querySelector('app-root'), 'login-page');
    this.loginForm = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
  }

  convertToFormData() {
    const params = new URLSearchParams();
    params.append('username', this.loginForm.get('username').value);
    params.append('password', this.loginForm.get('password').value);
    params.append('client_id', 'prime-apps');
    params.append('grant_type', 'password');
    return params;
  }

  login() {
    if (this.loginForm.valid) {
      this.isAuthLoading = true;
      this.authService.login(this.convertToFormData()).subscribe(
        (response) => {
          this.authService.getOnlineUserPermissions().subscribe({
            next: (perms) => {
              localStorage.setItem('permissions', JSON.stringify(perms));
              window.location.reload();
              this.router.navigate(['/']);
            },
            error: (err) => {
              console.error(err);
            },
          });
        },
        (err) => {
          this.isAuthLoading = false;
          console.error(err);
          this.toastr.error(err.error.error_description, 'Error');
        }
      );
    } else {
      this.toastr.error('Hello world!', 'Toastr fun!');
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
  }
}
