<li class="nav-item dropdown user-menu">
	<a class="nav-link dropdown-toggle" data-toggle="dropdown" (click)="toggleDropdownMenu()">
		<app-user-avatar [username]="authService.getUsername()" width="25.6px"></app-user-avatar>
	</a>
	<ul #dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
		<!-- User image -->
		<li class="user-header bg-primary">
			<app-user-avatar [username]="authService.getUsername()" width="90px"></app-user-avatar>

			<p>{{authService.getUsername()}} </p>
		</li>
		<!-- Menu Body -->
		<!-- <li class="user-body">
			<div class="row">
				<div class="col-4 text-center">
					<a href="#">Followers</a>
				</div>
				<div class="col-4 text-center">
					<a href="#">Sales</a>
				</div>
				<div class="col-4 text-center">
					<a href="#">Friends</a>
				</div>
			</div>
		</li> -->
		<!-- Menu Footer-->
		<li class="user-footer">
			<!-- <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
				Profile
			</a> -->
			<a (click)="logout()" class="btn btn-default btn-flat float-right">Sign out</a>
		</li>
	</ul>
</li>