export class Address {
  Id?: number;
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  Email?: string;
  Country?: string;
  CountryCode?: string;
  State?: string;
  StateId?: string;
  City?: string;
  CityId?: string;
  Address1?: string;
  Address2?: string;
  PostalCode?: string;
  PhoneNumber?: string;
  FaxNumber?: string;
  Notes?: string;
  Deleted?: boolean;
}
