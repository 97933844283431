<div class="card">
	<div class="card-body">
		<div class="card-title">
			<h4>Vendor Information</h4>
		</div>
		<div class="card-text">
			<div class="row">
				<div class="col-sm">
					<div class="row">
						<div class="col-sm">
							<strong>Distributor:</strong>
						</div>
						<div class="col-sm ml-4 ml-md-0">
							{{ shopOrderInfo.VendorId }}
						</div>
					</div>

					<div class="row">
						<div class="col-sm">
							<strong>Name:</strong>
						</div>
						<div class="col-sm ml-4 ml-md-0">
							{{ shopOrderInfo.VendorName }}
						</div>
					</div>
				</div>

				<div class="col-sm">
					<div class="row">
						<div class="col-sm">
							<strong>Mobile Number:</strong>
						</div>
						<div class="col-sm ml-4 ml-md-0">
							{{ shopOrderInfo.VendorPhoneNumber }}
						</div>
					</div>

					<div class="row">
						<div class="col-sm">
							<strong>Email:</strong>
						</div>
						<div class="col-sm ml-4 ml-md-0">
							{{ shopOrderInfo.VendorEmail }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>