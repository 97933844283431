import { Component, Input, OnInit } from '@angular/core';
import { Disposition } from '../../models/disposition';
import { ShopOrderInfo } from '../../models/shop-order-info';

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.scss'],
})
export class OrderItemsComponent implements OnInit {
  @Input()
  public disposition: Disposition;
  @Input()
  public shopOrderInfo: ShopOrderInfo;
  constructor() {}

  ngOnInit(): void {}
}
