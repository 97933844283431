import { ShopOrderInfo } from './../../models/shop-order-info';
import { Component, Input, OnInit } from '@angular/core';
import { Disposition } from '../../models/disposition';

@Component({
  selector: 'app-vendor-information',
  templateUrl: './vendor-information.component.html',
  styleUrls: ['./vendor-information.component.scss'],
})
export class VendorInformationComponent implements OnInit {
  @Input()
  public disposition: Disposition;
  @Input()
  public shopOrderInfo: ShopOrderInfo;
  constructor() {}

  ngOnInit(): void {}
}
