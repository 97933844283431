import { zip } from 'rxjs';
import { FulfillmentService } from './../../utils/services/fulfillment.service';
import { PaymentService } from './../../utils/services/payment.service';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Fulfillment } from '../../models/fulfillment';
import { Payment } from '../../models/payment';
import { Log } from '../../models/log';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss'],
})
export class LogComponent implements OnInit, AfterViewInit {
  @Input()
  public orderId: number;

  public payments: Payment[];
  public fulfillments: Fulfillment[];
  public logs: Log[];
  public dataSource = new MatTableDataSource<Log>([]);
  public displayedColumns: string[] = [
    'formattedProcess',
    'formattedStatus',
    'formattedLastModifiedDate',
    'remarks',
    'createdBy',
  ];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  @ViewChild(MatSort)
  public sort: MatSort;

  constructor(
    private readonly paymentService: PaymentService,
    private readonly fulfillmentService: FulfillmentService
  ) {}

  ngOnInit(): void {
    this.paymentService.getDispositionPayments(this.orderId).subscribe({
      next: (payments) => {
        this.payments = payments;
        if (this.fulfillments) {
          this.logs = [...payments, ...this.fulfillments];
          this.setDataSource();
        } else {
          this.logs = payments;
        }
      },
      error: (err) => {
        console.error('no payment log');
        this.payments = [];
      },
      complete: () => {
        this.setDataSource();
      },
    });
    this.fulfillmentService.getDispositionFulfillments(this.orderId).subscribe({
      next: (fulfillments) => {
        this.fulfillments = fulfillments;
        if (this.payments) {
          this.logs = [...this.payments, ...fulfillments];
          this.setDataSource();
        } else {
          this.logs = fulfillments;
        }
      },
      error: (err) => {
        console.error('no fullfilment log');
        this.fulfillments = [];
      },
      complete: () => {
        this.setDataSource();
      },
    });
  }

  setDataSource() {
    this.dataSource = new MatTableDataSource<Log>(
      this.logs.map((log) => {
        log.formattedLastModifiedDate = formatDate(
          log.lastModifiedDate,
          'medium',
          'EN'
        );
        log.formattedProcess = log.paymentType ? 'Payment' : 'Fulfillment';
        log.formattedStatus = log.status ? log.status : log.fulfillmentStatus;
        return log;
      })
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.sort.sort({
      id: 'formattedLastModifiedDate',
      start: 'desc',
      disableClear: true,
    });
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  getStatus(log: Log) {
    return log.status ? log.status : log.fulfillmentStatus;
  }
}
