import { AdditionalCharge } from './additional-charge';
import { Address } from './address';
import { OrderItem } from './order-item';

export class ShopOrderInfo {
  BillingAddress?: Address;
  ShippingAddress?: Address;
  VendorName?: string;
  VendorPhoneNumber?: string;
  VendorEmail?: string;
  Items?: OrderItem[];
  OtherCharges?: AdditionalCharge[];
  Id?: number;
  Origin?: string;
  VendorId?: string;
  CustomerId?: string;
  CustomerName?: string;
  TransactionDate?: Date;
  PaymentMethod?: number;
  DeliveryMethod?: number;
  PaymentReference?: string;
  TotalAmount?: number;
  TotalDiscount?: number;
  TotalPoints?: number;
  TotalMarkup?: number;
  ShippingFee?: number;
  Description?: string;
  Remarks?: string;
}
