import { Component, Input, OnInit } from '@angular/core';
import { Address } from '../../models/address';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
  @Input()
  public title = 'Address';
  @Input()
  public address: Address;
  constructor() {}

  ngOnInit(): void {}

  getAddress() {
    const address = [];

    if (this.address.City) {
      address.push(this.address.City);
    }

    if (this.address.State) {
      address.push(this.address.State);
    }

    if (this.address.Country) {
      address.push(this.address.Country);
    }

    return address.join(', ');
  }
}
