export class Fulfillment {
  dispositionId?: number;
  id?: number;
  status?: string;
  courierType?: string;
  remarks?: string;
  active?: boolean;
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
}
