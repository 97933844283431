<div class="card">
  <div class="card-body">
    <div class="card-title">
      <h4>{{ title }}</h4>
    </div>
    <div class="card-text">
      <div>
        <i class="fas fa-user fa-fw mr-2"></i>
        {{ address.FirstName }} {{ address.LastName }}
      </div>
      <div>
        <i class="fas fa-phone-alt fa-fw mr-2"></i>
        {{ address.PhoneNumber }}
      </div>
      <div>
        <i class="fas fa-envelope fa-fw mr-2"></i>
        {{ address.Email }}
      </div>
      <div>
        <i class="fas fa-map-marker fa-fw mr-2"></i>
        {{ address.Address1 }}
        {{ address.Address2 }}
        <p>{{ getAddress() }}</p>
      </div>
    </div>
  </div>
</div>
