import { Disposition } from './disposition';

export class Commission {
  dispositionId?: number;
  id?: number;
  commissionType?: string;
  currency?: string;
  amount?: number;
  remarks?: string;
  isSent?: boolean;
  state?: string;
  usdPrimeConversionValue?: number;
  sentDate?: Date;
  sentBy?: string;
  active?: boolean;
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
  dispositionDTO?: Disposition;
}
