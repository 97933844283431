import { CommissionService } from './utils/services/commission.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/main/header/header.component';
import { FooterComponent } from './pages/main/footer/footer.component';
import { MenuSidebarComponent } from './pages/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from './views/blank/blank.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './views/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesDropdownMenuComponent } from './pages/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import { NotificationsDropdownMenuComponent } from './pages/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import { AppButtonComponent } from './components/app-button/app-button.component';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { UserDropdownMenuComponent } from './pages/main/header/user-dropdown-menu/user-dropdown-menu.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ActiveOrdersComponent } from './pages/active-orders/active-orders.component';
import { PaymentsListComponent } from './views/payments-list/payments-list.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { OrderInformationComponent } from './views/order-information/order-information.component';
import { VendorInformationComponent } from './views/vendor-information/vendor-information.component';
import { OrderItemsComponent } from './views/order-items/order-items.component';
import { AddressComponent } from './views/address/address.component';
import { OrderSummaryComponent } from './views/order-summary/order-summary.component';
import { LogComponent } from './views/log/log.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { StatusFormComponent } from './views/status-form/status-form.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FulfillmentsComponent } from './pages/fulfillments/fulfillments.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UserAvatarComponent } from './views/user-avatar/user-avatar.component';
import { TokenInterceptor } from './utils/interceptors/token-http.interceptor';
import { CommissionDetailsComponent } from './pages/commission-details/commission-details.component';
import { StatusInfoDialogComponent } from './views/status-info-dialog/status-info-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PinListComponent } from './views/pin-list/pin-list.component';
import { PinDetailsComponent } from './pages/pin-details/pin-details.component';
import { MessageDialogComponent } from './views/message-dialog/message-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommissionListComponent } from './views/commission-list/commission-list.component';
import { OrdersPinsComponent } from './pages/orders-pins/orders-pins.component';
import { OrdersCommissionComponent } from './pages/orders-commission/orders-commission.component';
import { OrdersRtsComponent } from './pages/orders-rts/orders-rts.component';
import { RtsDetailsComponent } from './pages/rts-details/rts-details.component';
import { CommisionFormComponent } from './views/commision-form/commision-form.component';

registerLocaleData(localeEn, 'en-EN');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesDropdownMenuComponent,
    NotificationsDropdownMenuComponent,
    AppButtonComponent,
    UserDropdownMenuComponent,
    ActiveOrdersComponent,
    PaymentsListComponent,
    PaymentsComponent,
    OrderDetailsComponent,
    OrderInformationComponent,
    VendorInformationComponent,
    OrderItemsComponent,
    AddressComponent,
    OrderSummaryComponent,
    LogComponent,
    StatusFormComponent,
    FulfillmentsComponent,
    UserAvatarComponent,
    CommissionDetailsComponent,
    StatusInfoDialogComponent,
    PinListComponent,
    PinDetailsComponent,
    MessageDialogComponent,
    CommissionListComponent,
    OrdersPinsComponent,
    OrdersCommissionComponent,
    OrdersRtsComponent,
    RtsDetailsComponent,
    CommisionFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
