import { ShopOrderInfo } from './../../models/shop-order-info';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService
  ) {}

  getOrderInfo(orderId: number) {
    const url = `${environment.shop_api}/order-info/${orderId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<ShopOrderInfo>(url, { headers });
  }
}
