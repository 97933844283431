import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Payment } from '../../models/payment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService
  ) {}

  getPaymentsList(subpath: string = 'all') {
    const url = `${environment.oms_service}/payment/${subpath}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Payment[]>(url, { headers });
  }

  getDispositionPayments(dispositionId: number) {
    const url = `${environment.oms_service}/payment/list/${dispositionId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Payment[]>(url, { headers });
  }

  getPaymentDetails(paymentId: number) {
    const url = `${environment.oms_service}/payment/${paymentId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Payment>(url, { headers });
  }

  changeStatus(payment: Payment) {
    const url = `${environment.oms_service}/payment/new`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<Payment>(url, payment, { headers });
  }
}
