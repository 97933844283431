import { Commission } from './../../models/commission';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommissionService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService
  ) {}

  sendCommission(dispositionId: number): Observable<Commission> {
    const url = `${environment.oms_service}/commission/credit/disposition/${dispositionId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Commission>(url, { headers });
  }

  addCommission(commission: Commission) {
    const url = `${environment.oms_service}/commission/addup`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<Commission>(url, commission, { headers });
  }

  modifyCommission(commission: Commission) {
    const url = `${environment.oms_service}/commission/modify`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.post<Commission>(url, commission, { headers });
  }

  getCommissionsForDisposition(
    dispositionId: number
  ): Observable<Commission[]> {
    const url = `${environment.oms_service}/commission/list/${dispositionId}`;
    const headers = {
      Authorization: `Bearer ${this.authService.getAuthToken()}`,
    };
    return this.httpClient.get<Commission[]>(url, { headers });
  }
}
